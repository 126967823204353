import React, { useRef, useEffect, useState } from 'react'

import { useInView } from 'react-intersection-observer';

import './styles/globals.scss';

import Header from "./components/header";
import Section1 from './components/section-1';
import Section2 from './components/section-2';
import Section3 from './components/section-3';
import Section4 from './components/section-4';
import Section5 from './components/section-5';
import Section6 from './components/section-6';
import Section7 from './components/section-7';
import Footer from './components/footer';
import Disclaimer from './components/disclaimer';
import Loader from './components/loader';
import Compatibility from './components/compatibility';

import { gsap } from 'gsap';

const App = () => {
  const pageContainer = useRef(null);  
  const configScrollRef = useRef(null);
  const techScrollRef = useRef(null);  
  const waitlistScrollRef = useRef(null);      
  const section1Ref = useRef(null);
  const [section2Ref, section2InView] = useInView({ threshold: 0.8 });
  const [section3Ref, section3InView] = useInView({ threshold: 0.5 });  
  const [section4Ref, section4InView] = useInView({ threshold: 0.5 });
  const [section5Ref, section5InView] = useInView({ threshold: 0.5 });
  const [section6Ref, section6InView] = useInView({ threshold: 0.5 });
  const [section7Ref, section7InView] = useInView({ threshold: 0.5 });

  const [section1Loaded, setSection1Loaded] = useState(false);
  const [section2Loaded, setSection2Loaded] = useState(false);
  const [section3Loaded, setSection3Loaded] = useState(false);
  const [section4Loaded, setSection4Loaded] = useState(false);
  const [section5Loaded, setSection5Loaded] = useState(false);
  const [section6Loaded, setSection6Loaded] = useState(false);
  const [section7Loaded, setSection7Loaded] = useState(false);

  const [compatibilityVisible, setCompatibilityVisible] = useState(false);     
  const [isLoading, setIsLoading] = useState(true);
  const [showScrollPrompt, setShowScrollPrompt] = useState(false);

  const hideLoader = () => {
      pageContainer.current.style = {};
      setShowScrollPrompt(true);
      window.addEventListener('scroll', hideScrollPrompt);
      setIsLoading(false);
      section1Ref.current.fadeImageIn();
  }

  const hideScrollPrompt = () => {
    setShowScrollPrompt(false);
    window.removeEventListener('scroll', hideScrollPrompt);
  };

  const toggleCompatibilityView = () => {

    if(compatibilityVisible) {            
        gsap.to('.compatibility-mask', {opacity: 0, duration: 0.5});
        gsap.to('.compatibility-container', {x: '100%', duration: 0.5});
        gsap.to('.compatibility-wrapper', {width: 0, duration: 0});
    } else {
        gsap.to('.compatibility-wrapper', {width: '100%', duration: 0});
        gsap.to('.compatibility-mask', {opacity: 1, duration: 0.5});            
        gsap.to('.compatibility-container', {x: 0, duration: 0.5});
    }

    setCompatibilityVisible(!compatibilityVisible);
        
};


  const checkSectionsLoaded = () => {

    if(section1Loaded && section2Loaded && section3Loaded && section4Loaded && section5Loaded && section6Loaded && section7Loaded ) {
      return true;
    } else {
      return false;
    }

  };

  useEffect(() => {
    const hasLoaded = checkSectionsLoaded();

    if(hasLoaded) {            
      hideLoader();
    }

  }, [section1Loaded, section2Loaded, section3Loaded, section4Loaded, section5Loaded, section6Loaded, section7Loaded]);
  
  return (
    <main ref={pageContainer} style={{height: '0px', overflow: 'hidden'}}>

      <Header 
        configRef={configScrollRef}
        technologyRef={techScrollRef}
        waitlistRef={waitlistScrollRef}
        configInView={section2InView} 
        technologyInView={section3InView} 
        section4InView={section4InView}
        section5InView={section5InView}
        section6InView={section6InView}
        waitlistInView={section7InView} 
        
        toggleCompatibilityVisibility={toggleCompatibilityView}
        
        />
      
      <Section1 setSectionLoaded={setSection1Loaded} ref={section1Ref} />
      <Section2 setSectionLoaded={setSection2Loaded} inViewRef={section2Ref} scrollRef={configScrollRef} toggleCompatibilityView={toggleCompatibilityView} />
      <Section3 setSectionLoaded={setSection3Loaded} inViewRef={section3Ref} scrollRef={techScrollRef} />
      <Section4 setSectionLoaded={setSection4Loaded} inViewRef={section4Ref} />
      <Section5 setSectionLoaded={setSection5Loaded} inViewRef={section5Ref} />
      <Section6 setSectionLoaded={setSection6Loaded} inViewRef={section6Ref} />
      <Section7 setSectionLoaded={setSection7Loaded} inViewRef={section7Ref} scrollRef={waitlistScrollRef} />
      <Footer />
      <Compatibility toggleVisibility={toggleCompatibilityView} />

      {isLoading && 
        (<Loader />)
      }

        <div className={`${showScrollPrompt ? 'visible' : ''} down-chevron`}>
            <p>スクロールダウン</p>
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#FFF" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
        </div> 

        <Disclaimer />
    </main>
  )
}

export default App