import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import '../styles/section-1.scss'

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//assets
import horizontalVideo from '../videos/intro.mp4';
import verticalVideo from '../videos/intro-vertical.mp4';
import introScreen from '../images/intro-screen.jpg';

gsap.registerPlugin(ScrollTrigger);

const Section1 = forwardRef((props, ref) => {    
    const [imageLoaded, setImageLoaded] = useState(false);

    const videoRef = useRef(null);  
    const UA = window.navigator.userAgent;
    //video does not scrub properly on Mac Firefox
    const isMacFireFox = UA.includes('Mac OS') ? UA.includes('Firefox') ? true : false : false;    

    const fadeImageIn = () => {
        if(isMacFireFox) {
            gsap.to('.intro-screen', {duration: 0.5, autoAlpha: 1});
        }
    }

    const initScrollAnimation = () => {
        const video = videoRef.current;

        //gsap definitions
        const tl = gsap.timeline({
            scrollTrigger: {
              trigger: "#section-1",
              start: "top top",
              end: "+=3000",              
              pin: true,
              scrub: 0.5
            }
        });                

        if(!isMacFireFox) {
            tl.to(video, {
                currentTime: + 7,
                snap: 'frame',
                ease: 'none'
            });
        }

        tl.addLabel('video-end');

        tl.to('#text-1', { autoAlpha: 1, top: '40%'}, 'video-end')          
         .to('#text-1', { autoAlpha: 0, top: '0%'}, '>')     
         .to('#text-2', { autoAlpha: 1, top: '20%'}, '<-0.1')         
         .to('#text-2', { autoAlpha: 0, top: '-20%'},'>');        

    };

    const insertVideo = () => {
        const videoSrc = window.innerWidth < 500 ? verticalVideo : horizontalVideo;
        const classString = window.innerWidth < 500 ? 'vertical-video' : 'horizontal-video';

        return (
            <video ref={videoRef} id='intro-video' className={classString} src={videoSrc} muted onPlay={videoPause} onCanPlayThrough={videoInit} playsInline autoPlay={true}></video>
        )
    }

    const videoPause = () => {
        videoRef.current.pause();        
    };

    const videoInit = async () => {

        if(!isMacFireFox) {   
            props.setSectionLoaded(true);
        }

        try {
            await videoRef.current.play();
        } catch(e) {

        }
    };

    useImperativeHandle(ref, () => ({
        fadeImageIn,        
    }));

    useEffect(() => {
        let ctx = gsap.context(() => {                      
            initScrollAnimation();
        });
        
        if(isMacFireFox) {    
            //wait for image to load
            const img = new Image();
            img.src = introScreen;
            img.onload = () => {                
                props.setSectionLoaded(true);
                setImageLoaded(true);
            };                             
        }

        return () => ctx.revert();
    }, []);     

    return (
        <section id='section-1'>

            {!isMacFireFox &&
                (            
                    insertVideo()
            )}

            {isMacFireFox && imageLoaded &&
                (
                <img className='intro-screen' src={introScreen} />
            )}            
            

            <div className='text-container'>

                <div id='text-1'>
                    <div className='top-bar'></div>
                    <p className='title'>A Waifu for the new age.</p>
                    <p className='text'>A precision engineered dakimakura to keep you safe in any situation.</p>
                </div>

                <div id='text-2'>
                    <div className='top-bar'></div>
                    <p className='text'>Deployed at impacts greater than</p>
                    <p className='title'>20<sup>mph</sup></p>                
                </div>

            </div>

        </section>
    )
});

export default Section1