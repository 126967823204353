import React, { useEffect, useState } from 'react'
import '../styles/section-6.scss'

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//assets
import weave from '../images/weave.png';

gsap.registerPlugin(ScrollTrigger);

const Section6 = ({ inViewRef, scrollRef, setSectionLoaded }) => {

    const initScrollAnimation = () => {

        //gsap definitions
        const tl = gsap.timeline({
            scrollTrigger: {
              trigger: "#section-6",
              start: "top top",
              end: "+=800",              
              pin: true,
              scrub: 1
            }
        });

        tl.addLabel('animation-2-start');

        if(window.innerWidth < 712) {
            tl.to('#weave', { autoAlpha: 1, top: '50%'}, 'animation-2-start') 
            .to('#text-4', { autoAlpha: 1, top: '20%'}, 'animation-2-start');      
        } else if(window.innerWidth < 880) {
            tl.to('#weave', { autoAlpha: 1, top: '50%'}, 'animation-2-start') 
            .to('#text-4', { autoAlpha: 1, top: '20%'}, 'animation-2-start');    
        } else {
            tl.to('#weave', { autoAlpha: 1, top: '50%'}, 'animation-2-start') 
            .to('#text-4', { autoAlpha: 1, top: '50%'}, 'animation-2-start');          
        }

        tl.set({}, {}, "+=0.1");  

    };

    useEffect(() => {
        let ctx = gsap.context(() => {
           initScrollAnimation();
        });

        setSectionLoaded(true);
        
        return () => ctx.revert();
    }, []);        
  

    return (
        <section id='section-6' ref={inViewRef}>
            <div className='left-container' ref={scrollRef}>
                <div id='text-4'>
                    <div className='top-bar'></div>
                    <p className='title'>An eternal bond.</p>
                    <p className='text'>Durable synthetic fibres and unbreakable emotional connection, mean LSW-001 comes with a lifetime guarantee.</p>
                </div>                
            </div>

            <div className='right-container'>
                <img id='weave' src={weave} alt='Cut through of weave tech' />
            </div>

        </section>
    )
}

export default Section6