import '../styles/disclaimer.scss';

const Disclaimer = ({ }) => {
   
    return (
        <p className='disclaimer'>
            Only airbags from certified suppliers should be installed
        </p>
    )
}

export default Disclaimer