import React, { useEffect, useState } from 'react'
import '../styles/section-3.scss'

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//assets
import door from '../images/door.png';
import whiteLogo from '../images/logo-white.svg';

gsap.registerPlugin(ScrollTrigger);

const Section3 = ({ inViewRef, scrollRef, setSectionLoaded }) => {

    const initScrollAnimation = () => {

        //gsap definitions
        const tl = gsap.timeline({
            scrollTrigger: {
              trigger: "#section-3",
              start: "top top",
              end: "+=800",              
              pin: true,
              scrub: 1
            }
        });

        tl.addLabel('animation-1-start')

        .to('#door', { autoAlpha: 1, top: '20%'}, 'animation-1-start') 
        .to('#text-3', { autoAlpha: 1, top: '50%'}, 'animation-1-start') 
        .to('#door', { autoAlpha: 0, top: '-60%'}, '>')     
        .to('#text-3', { autoAlpha: 0, top: '60%'},'<')       

        .to('#section-3', { backgroundColor: '#1e1e1e'}, '<+=0.2'); 

        if(window.innerWidth > 880) {
           // console.log('CHANGE HEADER');
            tl.to('#configurator-button', { color: '#FFF'}, '<')
            .to('#technology-button', { color: '#FFF'}, '<')
            .to('#instagram-button svg', { color: '#FFF'}, '<')
            .to('#waitlist-button', { color: '#000', backgroundColor: '#FFF'}, '<')
            .to('#compatibility-button', { color: '#FFF', borderColor: '#FFF'}, '<')
            .set('.main-logo', { attr: {src: whiteLogo }}, '<');
        }            

        tl.set({}, {}, "+=0.1"); 
        
    };

    useEffect(() => {
        let ctx = gsap.context(() => {
           initScrollAnimation();
        });

        setSectionLoaded(true);
        
        return () => ctx.revert();
    }, []);        
  

    return (
        <section id='section-3' ref={inViewRef}>
            <div className='left-container' ref={scrollRef}>
                <img id='door' src={door} alt='Crumpled car door' />               
            </div>

            <div className='right-container'>
                <div id='text-3'>
                    <div className='top-bar'></div>
                    <p className='title'>A loved one there the millisecond you need them.</p>
                    <p className='text'>Powered by precision sensors, low yield explosives and unconditional love.</p>
                </div>
            </div>

        </section>
    )
}

export default Section3