import React, { useRef, useEffect } from 'react'
import '../styles/section-4.scss'

import { gsap } from 'gsap';

import { useInView } from 'react-intersection-observer';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//assets
import heroVideo from '../videos/hero-clean-audio.mp4';

gsap.registerPlugin(ScrollTrigger);

const Section4 = ({ setSectionLoaded, inViewRef }) => {    
    const videoRef = useRef(null);  

    const { ref } = useInView({ threshold: 0.2, onChange: (inView) => {

        if(!inView) {
            videoRef.current.muted = true;
        }

    } });

    const videoClicked = () => {
        const video = videoRef.current;

        video.muted = !video.muted;
    };

    const initScrollAnimation = () => {
        const video = videoRef.current;

        //gsap definitions
        gsap.timeline({
            scrollTrigger: {
              trigger: "#section-4",
              start: "top top",
              end: "+=1000",              
              pin: true, 
              onEnter: () => {

                video.play();

              }
            }
        });          
  
    };

    const videoInit = async () => {
        videoRef.current.pause();
        setSectionLoaded(true);
    };
    
    useEffect(() => {
        let ctx = gsap.context(() => {                      
            initScrollAnimation();
        });
        
        return () => ctx.revert();
    }, []);          

    return (
        <section id='section-4'  ref={(el)=> {inViewRef(el); ref(el);}}>

            <video onClick={videoClicked} ref={videoRef} id="hero-video" src={heroVideo} muted playsInline loop onCanPlayThrough={videoInit} autoPlay={true}></video>
    
        </section>
    )
}

export default Section4