import '../styles/footer.scss';

const Footer = ({ }) => {
   
    return (
        <footer>
            <a href='/privacy'>Privacy Policy</a>
        </footer>
    )
}

export default Footer