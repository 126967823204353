import React, { useState } from 'react'
import '../styles/compatibility.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import fetchWithTimeout from '../utils/fetchWithTimeout';

const Compatibility = ({toggleVisibility}) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [regError, setRegError] = useState(null);
    const [regConfirm, setRegConfirm] = useState(null);

    const submitReg = async (values) => {
        setIsSubmitting(true);
        setRegError(null);
        setRegConfirm(null);

        const response = await fetchWithTimeout(`/.netlify/functions/submit-reg`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({vehicleReg: values.vehicleReg})
        });
       
        if(response.status !== 200 && response.status !== 404) {
            setIsSubmitting(false);
            setRegError('Something went wrong, please try again')
            throw new Error(`Server responded with ${response.status}`);
        } 
        
        if(response.status === 404) {
            setRegConfirm(`Vehicle not found`);
        
            setIsSubmitting(false);
        } else {

            const json = await response.json();
            
            setRegConfirm(`Unfortunately your ${json.data.yearOfManufacture} ${json.data.make} is not currently compatible.`);
            
            setIsSubmitting(false);
        }
    };    

    const RegSchema = Yup.object().shape({
        vehicleReg: Yup.string()
        .min(
            7,
            'Invalid Reg',
          )        
        .required('Vehicle Reg is required')                     
    });

    return (
        <div className={'compatibility-wrapper'}>

            <div onClick={toggleVisibility} className={'compatibility-mask'}></div>

            <div className={'compatibility-container'}>

                <FontAwesomeIcon 
                    icon={solid("arrow-left")} 
                    onClick={toggleVisibility}
                    style={{color: "inherit"}} 
                    className={'back-button'} />

                <h1>Compatibility<br />Checker</h1>
                <p>Follow the steps below to see if your vehicle is UwU certified.</p>

                <Formik
                    initialValues={{ 
                        vehicleReg: '',
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={RegSchema}
                    onSubmit={(values) => submitReg(values)}>

                    {({errors}) => (    
                        <Form>   
                            { regError && 
                                <p className='error-message'>{regError}</p>
                            }

                            { errors.vehicleReg && 
                                <p className='error-message'>{errors.vehicleReg}</p>
                            }                                                   

                            <div className='form-input'>
                                <Field type="text" name="vehicleReg" placeholder="ENTER REG" />

                                <button disabled={isSubmitting} type="submit">Check Vehicle</button>
                            </div>

                            { regConfirm && 
                                <div>
                                    <p className='confirm-message'>{regConfirm}</p>
                                    <p className='confirm-title'>{'Give your waifu body pillow a huggy wuggy :('}</p>                                    
                                </div>
                            }                             
                        </Form>
                    )}

                </Formik>              
            </div>
        </div>
    );
}

export default Compatibility