import image24 from '../../../images/animations/hiro/023.png'
import image25 from '../../../images/animations/hiro/024.png'
import image26 from '../../../images/animations/hiro/025.png'
import image27 from '../../../images/animations/hiro/026.png'
import image28 from '../../../images/animations/hiro/027.png'
import image29 from '../../../images/animations/hiro/028.png'
import image30 from '../../../images/animations/hiro/029.png'
import image31 from '../../../images/animations/hiro/030.png'
import image32 from '../../../images/animations/hiro/031.png'
import image33 from '../../../images/animations/hiro/032.png'
import image34 from '../../../images/animations/hiro/033.png'
import image35 from '../../../images/animations/hiro/034.png'
import image36 from '../../../images/animations/hiro/035.png'
import image37 from '../../../images/animations/hiro/036.png'
import image38 from '../../../images/animations/hiro/037.png'
import image39 from '../../../images/animations/hiro/038.png'
import image40 from '../../../images/animations/hiro/039.png'
import image41 from '../../../images/animations/hiro/040.png'
import image42 from '../../../images/animations/hiro/041.png'
import image43 from '../../../images/animations/hiro/042.png'

const hiroArray = [
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
    image37,
    image38,
    image39,
    image40,
    image41,
    image42,
    image43,    
]

export default hiroArray;