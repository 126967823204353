import React, { useRef, useEffect, useState } from 'react'
import '../styles/section-5.scss'

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

//assets
import crashVideo from '../videos/crash.mp4';
import crashScreen from '../images/crash-screen.jpg';

gsap.registerPlugin(ScrollTrigger);

const Section5 = ({ setSectionLoaded, inViewRef }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const videoRef = useRef(null);  
    const UA = window.navigator.userAgent;
    //video does not scrub properly on Mac Firefox
    const isMacFireFox = UA.includes('Mac OS') ? UA.includes('Firefox') ? true : false : false;    

    const initScrollAnimation = () => {
        const video = videoRef.current;

        //gsap definitions
        const tl = gsap.timeline({
            scrollTrigger: {
              trigger: "#section-5",
              start: "top top",
              end: "+=3000",              
              pin: true,
              scrub: 1
            }
        });

        tl.to(video, {
          currentTime: + 7,
          snap: 'frame',
          ease: 'none'
        });

        tl.to('#text-6', { autoAlpha: 1, top: '40%'}, '<')          
         .to('#text-6', { autoAlpha: 0, top: '0%'}, '>');                
  
    };

    const videoPause = () => {
        videoRef.current.pause();
    };

    const videoInit = async () => {
        
        if(!isMacFireFox) {   
            setSectionLoaded(true);
        }

        try {
            await videoRef.current.play();
        } catch(e) {

        }
    };
    
    useEffect(() => {
        let ctx = gsap.context(() => {                      
            initScrollAnimation();
        });
        
        if(isMacFireFox) {    
            //wait for image to load
            const img = new Image();
            img.src = crashScreen;
            img.onload = () => {                
                setSectionLoaded(true);
                setImageLoaded(true);
            };                             
        }

        return () => ctx.revert();
    }, []);          

    return (
        <section id='section-5' ref={inViewRef}>
            {!isMacFireFox &&
                (
                <video  ref={videoRef} id="crash-video" src={crashVideo} muted playsInline onPlay={videoPause} onCanPlayThrough={videoInit} autoPlay={true}></video>
            )}

            {isMacFireFox && imageLoaded &&
                (
                <img className='crash-screen' src={crashScreen} />
            )}               
            
            <div className='text-container'>

                <div id='text-6'>
                    <div className='top-bar'></div>
                    <p className='text'>Advanced care and protection processed in the blink of an eye</p>
                    <p className='title'>17<span>uwu/s</span></p>
                </div>

            </div>            
        </section>
    )
}

export default Section5