import React, { useRef, useEffect, useState } from 'react'
import '../styles/section-2.scss'

import Airbag from './animations/airbag';

import flower from '../images/flower.png';

export function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      );
  
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref]);
  
    return isIntersecting;
  }

  
const Section2 = ({ inViewRef, scrollRef, setSectionLoaded, toggleCompatibilityView }) => {
    const [activeChoice, setActiveChoice] = useState('Yuki');
    const [activeSize, setActiveSize] = useState('12');       
    const [flowerVisible, setFlowerVisible] = useState(false); 
    const airbagRef = useRef(null);
    const sectionRef = useRef(null);
    const yukiTextRef = useRef(null);
    const hiroTextRef = useRef(null);
    const renTextRef = useRef(null);
    const performanceTextRef = useRef(null);

    const isVisible = useIsVisible(sectionRef);

    useEffect(() => {        

        if(isVisible) {
            choiceClicked(activeChoice)
        }
        
    }, [isVisible])        

    const sizeClicked = (size) => {
        setActiveSize(size);
    };

    const choiceClicked = (choice) => {
        setActiveChoice(choice);

        const textElements = {
            'Yuki': yukiTextRef,
            'Hiro': hiroTextRef,
            'Ren': renTextRef
        }

        //hide all
        yukiTextRef.current.style.height = 0;
        hiroTextRef.current.style.height = 0;
        renTextRef.current.style.height = 0;

        airbagRef.current.startAnimation(choice);     
        textElements[choice].current.style.height = 'initial';
    };    

    const togglePerformance = () => {

        if(flowerVisible) {
            performanceTextRef.current.style.height = 0;
        } else {
            performanceTextRef.current.style.height = 'initial';
        }

        setFlowerVisible(!flowerVisible);        
    };

    return (
        <section id='section-2' ref={inViewRef}>
            <div className='left-container' ref={scrollRef}>
                <p className='sub-title'>LSW-001</p>
                <p ref={sectionRef} className='title'>Configurator</p>            

                <p className='sub-title'>Waifu / Husbando / Non-binaru partneru</p>  

                <button 
                    onClick={() => {choiceClicked('Yuki')}}
                    onMouseEnter={() => {choiceClicked('Yuki')}}
                    className={`${activeChoice === 'Yuki' ? 'active' : ''} title selection character-selection`} 
                    data-choice='Yuki' >
                        Yuki
                </button> 

                <p id='yuki-quote' ref={yukiTextRef} className='character-quote'>{`That was one close call, onii chan. With me your heart will never flatline! "nuzzles you and charges up defibrillator"`}</p> 

                <button 
                    onClick={() => {choiceClicked('Hiro')}}
                    onMouseEnter={() => {choiceClicked('Hiro')}}                    
                    className={`${activeChoice === 'Hiro' ? 'active' : ''} title selection character-selection`} 
                    data-choice='Hiro' >
                        Hiro
                </button> 

                <p id='hiro-quote' ref={hiroTextRef} className='character-quote'>{`Hmmph, next time you should be more careful. Still, I'm glad you're ok.`}</p> 

                <button 
                    onClick={() => {choiceClicked('Ren')}}
                    onMouseEnter={() => {choiceClicked('Ren')}}                    
                    className={`${activeChoice === 'Ren' ? 'active' : ''} title selection character-selection`} 
                    data-choice='Ren' >
                        Ren
                </button>               

                <p id='ren-quote' ref={renTextRef} className='character-quote'>{`BAKAAAAaaaaaa you almost got rekt >:3`}</p>                   

                <p className='sub-title'>Upgrades</p>  
                <p onClick={togglePerformance} className={`${flowerVisible ? 'active' : ''} title selection performance`}>Performance</p>
                <p ref={performanceTextRef} className='text'>Performance software upgrades, flowers and card dispatched to vehicle upon deployment</p>

                {/* <p className='sub-title'>Size</p>
                <button 
                    onClick={() => {sizeClicked('12')}}
                    className={`${activeSize === '12' ? 'active' : ''} title selection`} 
                    data-choice='12' >
                        12"
                </button> 
                <button 
                    onClick={() => {sizeClicked('14')}}
                    className={`${activeSize === '14' ? 'active' : ''} title selection`} 
                    data-choice='14' >
                        14"
                </button>        */}

                <button 
                    onClick={toggleCompatibilityView}
                    className={`config-button`}>
                        Pick me, onii chan
                </button>                                                

            </div>

            <div className='animation-container'>
                <Airbag ref={airbagRef} setSectionLoaded={setSectionLoaded} />
            </div>
            
            {flowerVisible &&
                (
                    <img className='flower' src={flower} alt='A plastic looking rose' />
                )
            }
                        
        </section>
    )
}

export default Section2 