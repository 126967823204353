import image19 from '../../../images/animations/yuki/018.png'
import image20 from '../../../images/animations/yuki/019.png'
import image21 from '../../../images/animations/yuki/020.png'
import image22 from '../../../images/animations/yuki/021.png'
import image23 from '../../../images/animations/yuki/022.png'
import image24 from '../../../images/animations/yuki/023.png'
import image25 from '../../../images/animations/yuki/024.png'
import image26 from '../../../images/animations/yuki/025.png'
import image27 from '../../../images/animations/yuki/026.png'
import image28 from '../../../images/animations/yuki/027.png'
import image29 from '../../../images/animations/yuki/028.png'
import image30 from '../../../images/animations/yuki/029.png'
import image31 from '../../../images/animations/yuki/030.png'
import image32 from '../../../images/animations/yuki/031.png'
import image33 from '../../../images/animations/yuki/032.png'
import image34 from '../../../images/animations/yuki/033.png'
import image35 from '../../../images/animations/yuki/034.png'
import image36 from '../../../images/animations/yuki/035.png'

const yukiArray = [
    image19,
    image20,
    image21,
    image22,
    image23,
    image24,
    image25,
    image26,
    image27,
    image28,
    image29,
    image30,
    image31,
    image32,
    image33,
    image34,
    image35,
    image36,
]

export default yukiArray;