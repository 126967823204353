import React, { useRef, useEffect, useState, useImperativeHandle, forwardRef } from 'react'

import AnimateImages from "@its2easy/animate-images";

//image sequences
import yukiArray from './sequences/yuki';
import hiroArray from './sequences/hiro';
import renArray from './sequences/ren';



const Airbag = forwardRef((props, ref) => {
    const yukiElement = useRef(null);
    const hiroElement = useRef(null);
    const renElement = useRef(null);
    const [yukiAnimationSequence, setYuki] = useState(null);
    const [hiroAnimationSequence, setHiro] = useState(null);
    const [renAnimationSequence, setRen] = useState(null);
    // const [yukiLoaded, setYukiLoaded] = useState(null);
    // const [hiroLoaded, setHiroLoaded] = useState(null);
    // const [renLoaded, setRenLoaded] = useState(null);

    let yukiLoaded = false;
    let hiroLoaded = false;
    let renLoaded = false;
    
    const checkImagesLoaded = () => {

        if(yukiLoaded && hiroLoaded && renLoaded) {
            props.setSectionLoaded(true);
        }
    };

    const initYuki = () => {

        setYuki(new AnimateImages(yukiElement.current, {
            images: yukiArray, /* required */
            preload: 'all',        
            loop: false,
            fps: 24,
            ratio: 1,
            fillMode: 'contain',
            poster: yukiArray[0],
            onPreloadFinished: () => {
                yukiLoaded = true;
                checkImagesLoaded();
            }
        }));     
    };

    const initHiro = () => {

        setHiro(new AnimateImages(hiroElement.current, {
            images: hiroArray, /* required */
            preload: 'all',        
            loop: false,
            fps: 24,
            ratio: 1,
            fillMode: 'contain',
            poster: hiroArray[0],
            onPreloadFinished: () => {
                hiroLoaded = true;
                checkImagesLoaded();
            }
        }));

    };    

    const initRen = () => {
        
         setRen(new AnimateImages(renElement.current, {
            images: renArray, /* required */
            preload: 'all',        
            loop: false,
            fps: 24,
            ratio: 1,
            fillMode: 'contain',
            poster: renArray[0],
            onPreloadFinished: () => {
                renLoaded = true;
                checkImagesLoaded();
            }
        }));        
    };    

    useImperativeHandle(ref, () => ({
        startAnimation,        
    }));

    const startAnimation = (char) => {
        const character = char.toLowerCase();
        const animElToStart = character === 'yuki' ? yukiElement : character === 'hiro' ? hiroElement : renElement;
        const animToStart = character === 'yuki' ? yukiAnimationSequence : character === 'hiro' ? hiroAnimationSequence : renAnimationSequence;

        //hide all
        yukiElement.current.style.opacity = 0;
        hiroElement.current.style.opacity = 0;
        renElement.current.style.opacity = 0;

        //reset, show, then play
        animToStart.reset();

        animElToStart.current.style.opacity = 1;

        animToStart.play();
        
    };

    useEffect(() => {        
        initYuki();
        initHiro();
        initRen();
    }, []);

    return (
        <div className='animation-canvas-container'>
            <canvas className='yuki' ref={yukiElement} width="800" height="800"></canvas>
            <canvas className='hiro' ref={hiroElement} width="800" height="800" style={{opacity: 0}}></canvas>
            <canvas className='ren' ref={renElement} width="800" height="800" style={{opacity: 0}}></canvas>
        </div>
    )
});

export default Airbag