import React from 'react'
import '../styles/loader.scss'

import loading from '../images/loading-wheel.png'

const Loader = () => {
    return (
        <div className={'loader-container'}>
            <img className='loader' src={loading} alt='' />
        </div>
    );
}

export default Loader